import React from "react"
import styled from "styled-components"
import Collapse from "@kunukn/react-collapse"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

import { Box as MuiBox } from "@material-ui/core"

const AccordionWrapper = styled.div`
  width: auto;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  padding: 8px 6px;

  border-radius: ${props => (props.active ? "4px 4px 0px 0px" : "4px")};

  background: ${props =>
    props.active
      ? props.theme.colors.secondary.lime
      : props.theme.colors.secondary.yellow};
  color: ${props => props.theme.colors.grey.black};

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`

const Title = styled.div`
  flex-grow: 1;
  padding-left: 8px;
`

const IconDiv = styled.div`
  height: 20px;
  width: 20px;
  background-color: ${props => `${props.theme.colors.grey.white}99`};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 16px;
    height: 16px;
  }
`

const collapseStyle = {
  background: "rgba(242, 242, 242, 0.5)",
  borderRadius: "0px 0px 4px 4px",
}

const Accordion = ({ children, index, header, active, setActiveIndex }) => {
  const [isOpen, setIsOpen] = React.useState(active)

  React.useEffect(() => {
    setIsOpen(active)
  }, [active])

  const handleAccordionHeaderClick = () => {
    if (isOpen) {
      setActiveIndex(-1)
      setIsOpen(false)
    } else {
      setActiveIndex(index)
      setIsOpen(true)
    }
  }

  return (
    <MuiBox pb={1}>
      <AccordionWrapper onClick={handleAccordionHeaderClick} active={isOpen}>
        <Header active={isOpen}>
          <Title>{header}</Title>
          <IconDiv>{isOpen ? <RemoveIcon /> : <AddIcon />}</IconDiv>
        </Header>
      </AccordionWrapper>
      <Collapse
        style={collapseStyle}
        isOpen={isOpen}
        transition="height 0.2s cubic-bezier(.4, 0, .2, 1)"
      >
        {children}
      </Collapse>
    </MuiBox>
  )
}

export default Accordion
