import React from "react"
import styled from "styled-components"
import theme from "@assets/styles/theme"

import AppLayout from "@components/layout/App"
import MobileSidebar from "@components/modules/leveling-criteria/MobileSidebar"
import Accordion from "@components/elements/Accordion"

import HeaderBg from "@assets/career-levels/banner_career_band.jpg"
import HeaderBgMobile from "@assets/career-levels/banner_career_band_mobile.jpg"

import BulletBlue from "@assets/styles/bullet-blue.svg"

const PageContainer = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const PAGE_HEADER_HEIGHT = "212px"
const PAGE_GREY_HEIGHT = "132px"

const PageHeader = styled.div`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  height: ${PAGE_HEADER_HEIGHT};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;

  @media (max-width: 1023px) {
    background-image: url(${HeaderBgMobile});
    height: auto;
  }
`

const PageHeaderBoxes = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderGrey = styled.div`
  background-color: rgba(63, 63, 63, 0.9);
  color: ${theme.colors.grey.white};
  flex: 1;
  height: ${PAGE_GREY_HEIGHT};

  overflow: hidden;

  @media (max-width: 1023px) {
    background-color: transparent;
    padding: 0;
  }
`

const PageHeaderYellow = styled.div`
  background-color: ${theme.colors.secondary.yellow};
  width: 40%;
  height: 60px;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderTextContainer = styled.div`
  color: ${theme.colors.grey.white};
  width: min(1140px, calc(100vw - 32px));
  padding: 24px 0;
  z-index: 2;
  height: ${PAGE_GREY_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 0;
    height: auto;
  }
`

const PageHeaderH2 = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 0 8px 0;
  max-width: 50%;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 21px;
    margin: 30px 0;
    max-width: none;
  }
`

const Content = styled.div`
  flex-grow: 1;
  padding: 18px 36px;
  color: #666666;

  @media (max-width: 1023px) {
    padding: 18px;
  }

  > svg {
    margin-right: 4px;
  }
`

const AccordionContentUl = styled.ul`
  background: #ffffff;
  border: 1px solid ${theme.colors.grey.grey};
  border-radius: 0px 0px 4px 4px;
  margin: 0;
  padding: 16px 16px 8px 16px;
  padding-inline-start: 24px;
  font-size: 14px;

  list-style-image: url(${BulletBlue});

  li {
    padding-left: 8px;
    margin-bottom: 1rem;
  }

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

const LevelingCriteriaPage = props => {
  const { pageContext } = props

  const [activeAccordionIndex, setActiveAccordionIndex] = React.useState(0)

  return (
    <AppLayout>
      <PageHeader>
        <PageHeaderBoxes>
          <PageHeaderGrey />
          <PageHeaderYellow />
        </PageHeaderBoxes>
        <PageHeaderTextContainer>
          <PageHeaderH2>{pageContext.name}</PageHeaderH2>
        </PageHeaderTextContainer>
      </PageHeader>
      <PageContainer>
        <MobileSidebar {...pageContext} />
        <Content>
          <Accordion
            header={`Description`}
            index={0}
            active={activeAccordionIndex === 0}
            setActiveIndex={() => setActiveAccordionIndex(0)}
          >
            <AccordionContentUl>
              {pageContext.descriptions.map(d => (
                <li key={d}>{d}</li>
              ))}
            </AccordionContentUl>
          </Accordion>

          {pageContext.selectedBand.headers.map((header, index) => {
            return (
              <Accordion
                header={header.header}
                key={`accordion-${index + 1}`}
                index={index}
                active={activeAccordionIndex === index + 1}
                setActiveIndex={() => setActiveAccordionIndex(index + 1)}
              >
                {/* TODO: add content in career-level.json */}
                <AccordionContentUl>
                  {header.content.map((c, idx) => (
                    <li key={`${header.header}-${idx}`}>{c}</li>
                  ))}
                </AccordionContentUl>
              </Accordion>
            )
          })}
        </Content>
      </PageContainer>
    </AppLayout>
  )
}

export default LevelingCriteriaPage
