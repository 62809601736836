import React from "react"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

import { getBandDetailsUrl } from "@helpers/url"

const Flexbox = styled.div`
  display: flex;
`

const MobileSidebar = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }

  background: ${props => props.bgColor};
  color: ${props => props.textColor};
  text-align: center;
  width: 100%;

  .wrapper {
    padding: 20px 0;
    .title {
      font-size: 1.5rem;
    }
  }
`

const MobileBandContainer = styled(Flexbox)`
  justify-content: space-around;

  background: ${props => props.theme.colors.grey.greyBackground};
`

const MobileBand = styled.div`
  padding: 12px;

  a {
    color: ${props => props.theme.colors.grey.textDark};
    text-decoration: none;

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    :hover {
      text-decoration: underline;
    }
  }

  &.active a {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
`

const useStyles = makeStyles({
  root: {
    color: "white",
    padding: "8px 0",
  },
  icon: {
    color: "white",
  },
})

const MobileSidebarComponent = props => {
  const classes = useStyles()

  const careerLevel = props
  const selectedBand = props.selectedBand

  const [availableBands, setAvailableBands] = React.useState(careerLevel.bands)

  return (
    <MobileSidebar {...careerLevel}>
      <MobileBandContainer>
        {availableBands.map((b, index) => (
          <MobileBand
            key={`band-${index}`}
            className={b.code === selectedBand.code ? "active" : ""}
          >
            <Link to={getBandDetailsUrl(careerLevel.slug, b.code)}>
              {b.code}
            </Link>
          </MobileBand>
        ))}
      </MobileBandContainer>
    </MobileSidebar>
  )
}

export default MobileSidebarComponent
